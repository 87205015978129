<template>
    <form autocomplete="off" @submit.prevent="login" method="post" class="col-md-8 offset-md-2 text-center mt-4">
        <b-card-group>
            <b-card no-body class="p-4">
                <b-card-body>
                    <h3>GF Производство</h3>
                    <p class="text-muted">Вход в аккаунт</p>
                    <b-input-group class="mb-3">
                        <input class="form-control" placeholder="Логин" v-model="email" required>
                    </b-input-group>
                    <b-input-group class="mb-4">
                        <input :type="passwordInputType" class="form-control" placeholder="Пароль" v-model="password" required>
                        <b-input-group-append>
                            <b-button @click="changePasswordType()" variant="outline-dark">
                                <b-icon :icon="passwordShowedIcon"/>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                    <b-alert show variant="danger" v-if="error">
                        {{ error }}
                    </b-alert>
                    <b-row>
                        <b-col>
                            <b-button type="submit" variant="primary" class="px-4">Войти</b-button>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </b-card-group>
    </form>
</template>

<script>
const INPUT_TYPE_PASSWORD = 'password';
const INPUT_TYPE_TEXT = 'text';

export default {
    name: 'Login',
    data() {
        return {
            email: null,
            password: null,
            error: null,
            passwordInputType: INPUT_TYPE_PASSWORD,
        }
    },
    computed: {
        passwordShowedIcon() {
            return this.passwordInputType === INPUT_TYPE_PASSWORD ? 'eye-fill' : 'eye-slash-fill';
        },
    },
    methods: {
        changePasswordType() {
            if (this.passwordInputType === INPUT_TYPE_PASSWORD) {
                this.passwordInputType = INPUT_TYPE_TEXT;
            } else {
                this.passwordInputType = INPUT_TYPE_PASSWORD;
            }
        },
        login() {
            this.$auth.login({
                body: {
                    email: this.email,
                    username: this.email,
                    password: this.password
                },
                success() {
                    this.dispatchNotificationEvents();
                },
                error(response) {
                    this.error = response.body.error
                }
            })
        },
        onBarcodeScanned(data) {
            this.error = null

            this.$auth.login({
                url: 'v2/barcode_login',
                body: {
                    login_barcode: data.barcode,
                },
                success() {
                    this.dispatchNotificationEvents();
                },
                error(response) {
                    this.error = response.body.error;
                }
            })
        },
        dispatchNotificationEvents() {
            this.$root.dispatchNotificationEvents();
        },
        listenToBarcodeScanning() {
            this.$root.listenToBarcodeScanning(this);
        },
    },
    mounted() {
        this.listenToBarcodeScanning();

        if (this.$auth.check()) {
            this.$router.push('/welcome')
        }
    },
}
</script>
